<template>
    <main >
        <div class="header">
            <h1 class="heading">{{$t('stickers["Заказ стикеров"]')}}</h1>
        </div>
        <v-form class="timeline" 
            ref="form"
            v-model="valid"
            @submit="validate"
        >
            <div class="timeline__item">
                <span class="timeline__label">{{$t('stickers["Объект"]')}}</span>
                <div class="timeline__value">
                    <div class="field">
                        <div class="control">
                            <v-autocomplete solo flat outlined 
                                :placeholder="$t('stickers[\'Объект\']')"
                                :items="objects.list"
                                item-value="id"
                                item-text="name"
                                :rules="requiredRules"
                                v-model="object"
                            ></v-autocomplete>
                        </div>
                    </div>
                </div>
            </div>

            <div class="timeline__item">
                <span class="timeline__label">{{$t('stickers["Тип"]')}}</span>
                <div class="timeline__value">
                    <div class="field field_third" v-for="type in types" :key="type.id">
                        <label class="stickers-type">
                            <div class="stickers-type__img" 
                                :class="{'active': type.id === stickers.type}"
                            >
                                <img :src="require(`@/assets/img/sticker_type_${type.id}.jpg`)" :alt="type.name">
                            </div>
                            <input class="stickers-type__radio"
                                 :class="{'active': type.id === stickers.type}"
                                type="checkbox"
                                name="type"
                                :value="type.id === stickers.type"
                                @change="changeType(type.id)"
                            >
                            <span class="stickers-type__text">{{type.name}}</span>
                        </label>
                    </div>
                    <div class="stickers-logo" v-if="stickers.type === 2" key="stickers-type-logo">
                        <div class="field field_half">
                            <v-btn class="btn btn_primary" 
                                color="primary"
                                   small
                                   outlined
                                   dense
                                block
                                @click="addLogo"
                            >
                                {{$t('stickers["Загрузить лого"]')}}
                            </v-btn>
                            <input class="input-file" 
                                ref="file" 
                                type="file"
                                accept=".png, .eps"
                                @change="changeLogo"
                            >
                        </div>
                        <div class="field field_half field_center">
                            <p class="stickers-file-text" v-if="file_name">{{file_name}}</p>
                            <p class="stickers-file-text" v-else>{{$t('stickers["файл формата .eps или .png размером до 2мб"]')}}</p>
                        </div>
                    </div>
                    <div class="stickers__design" v-if="stickers.type === 3" key="stickers-type-design">
                        <p>{{$t('stickers["Мы свяжемся с вами по вопросу оформления вашего индивидуального макета стикера."]')}}</p>
                    </div>
                </div>
            </div>
            
            <div class="timeline__item">
                <span class="timeline__label">{{$t('stickers["Количество"]')}}</span>
                <div class="timeline__value">
                    <div class="field field_twothirds">
                        <div class="control">
                            <v-slider
                                hide-details
                                height="60"
                                thumb-size="60"
                                track-color="#f0f0f0"
                                min="1"
                                max="100"
                                v-model="stickers.qtt"
                            ></v-slider>
                        </div>
                    </div>
                    <div class="field field_third">
                        <div class="control" style="position: relative;">
                            <v-text-field solo outlined flat
                                :maxlength="3"
                                v-model="stickers.qtt"
                            ></v-text-field>
                            <span class="input__symbol">{{$t('stickers["шт."]')}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="timeline__item">
                <span class="timeline__label">{{$t('stickers["Адрес доставки"]')}}</span>
                <div class="timeline__value">
                    <div class="field field_half">
                        <div class="control">
                            <!-- <v-autocomplete solo flat outlined 
                                :placeholder="$t('stickers[\'Страна\']')"
                                :items="countries"
                                item-value="id"
                                item-text="name"
                                :rules="requiredRules"
                            ></v-autocomplete> -->
                            <v-text-field solo outlined flat
                                :placeholder="$t('stickers[\'Страна\']')"
                                :rules="requiredRules"
                                validate-on-blur
                                v-model="address.country"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="field field_half">
                        <div class="control">
                            <!-- <v-autocomplete solo flat outlined 
                                :placeholder="$t('stickers[\'Город\']')"
                                :items="cities"
                                item-value="id"
                                item-text="name"
                                :rules="requiredRules"
                            ></v-autocomplete> -->
                            <v-text-field solo outlined flat
                                :placeholder="$t('stickers[\'Город\']')"
                                :rules="requiredRules"
                                validate-on-blur
                                v-model="address.city"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <v-text-field solo outlined flat
                                :placeholder="$t('stickers[\'Адрес\']')"
                                :rules="requiredRules"
                                validate-on-blur
                                v-model="address.delivery_address"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="field field_half">
                        <div class="control">
                            <v-text-field solo outlined flat
                                :placeholder="$t('stickers[\'Дом\']')"
                                :rules="requiredRules"
                                validate-on-blur
                                v-model="address.house"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="field field_half">
                        <div class="control">
                            <v-text-field solo outlined flat
                                :placeholder="$t('stickers[\'Квартира / Офис\']')"
                                :rules="requiredRules"
                                validate-on-blur
                                v-model="address.apartment"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="field field_half">
                        <div class="control">
                            <v-text-field solo outlined flat
                                :placeholder="$t('stickers[\'Имя\']')"
                                :rules="requiredRules"
                                validate-on-blur
                                v-model="name"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="field field_half">
                        <div class="control">
                            <v-text-field class="input" solo outlined flat
                                :label="$t('stickers[\'Телефон\']')"
                                autocomplete="new-password"
                                ref="phone"
                                type="phone"
                                :rules="phoneRules"
                                validate-on-blur
                                v-model="phone"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="field">
                        <div class="stickers-total">
                            <span class="stickers-total__text">{{$t('stickers["Итоговая сумма заказа:"]')}}</span>
                            <span class="stickers-total__price">{{stickers.qtt * stickers.price}} {{landing.advanced.global.currency}}</span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <v-btn class="bnt btn_primary" 
                                color="primary"
                                   small
                                   outlined
                                   dense
                                block
                                type="submit"
                            >
                                {{$t('stickers["Заказать"]')}}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
        
        <PopupSuccess ref="PopupSuccess" title="Thank you!" :content="$t('stickers[\'Вы успешно оформили заказ стикеров!\']')"/>
    </main>
</template>

<script>
import Inputmask from 'inputmask'
import PopupSuccess from '@/components/Popup/PopupSuccess.vue'
import { mapState } from 'vuex';

export default {
    name: 'StickersCreate',
    components: {
        PopupSuccess
    },
    computed: {
        ...mapState(['objects','landing'])
    },
    data() {
        return {
            object: null,
            countries: [
                {id: 1, name: 'Хорватия'},
                {id: 2, name: 'Украина'},
                {id: 3, name: 'Франция'},
            ],
            cities: [
                {id: 1, name: 'Одесса'},
                {id: 2, name: 'Киев'},
                {id: 3, name: 'Львов'},
            ],
            types: [
                {id: 1, name: this.$t('stickers["Универсальный стикер"]'), price: 1},
                {id: 2, name: this.$t('stickers["С вашим логотипом"]'), price: 3},
                {id: 3, name: this.$t('stickers["Уникальный дизайн"]'), price: 5},
            ],
            address: {
                country: null,
                city: null,
                delivery_address: null,
                house: null,
                apartment: null
            },
            stickers: {
                qtt: 50,
                type: 1,
                price: 1,
                image: null,
            },
            name: null,
            phone: null,
            valid: false,
            requiredRules: [
                v => !!v || this.$t('stickers["Поле обязательно"]'),
            ],
            phoneRules: [
                v => !!v || this.$t('stickers["Поле обязательно"]'),
            ],
            is_complete: false,
            file_name: null,
        }
    },
    created() {
        this.$store.dispatch('objects/getList')
    },
    mounted() {
        this.$nextTick(() => {
            let inputmask = new Inputmask({
                mask: '+9{0,}',
                showMaskOnHover: false,
            });
            let input = this.$refs.phone.$el.querySelector('input');
            inputmask.mask(input);
            return;
        });
    },
    methods: {
        addLogo() {
            this.$refs.file.click();
        },
        changeLogo(e) {
            var files = e.target.files || e.dataTransfer.files;
    
            if (!files.length) {
                this.file_name = null;
                return;
            }
            
            // console.log(files[0].name)
            this.file_name = files[0].name;

            this.stickers.image = files[0];
            // this.createImage(files[0]);
        },
        changeType(type) {
            this.stickers.type = type;
            if (this.stickers.type === 1) {
                this.stickers.price = 1;
            } else if (this.stickers.type === 2) {
                this.stickers.price = 3;
            } else if (this.stickers.type === 3) {
                this.stickers.price = 5;
            }
        },
        // stikerCreate(e) {
        //     e.preventDefault();
        //     this.$refs.PopupSuccess.open();
        // },
        validate(e) {
            e.preventDefault();
            
            if (this.$refs.phone.$el.querySelector('input').inputmask.isComplete()) {
                this.is_complete = true;
            } else {
                this.is_complete = false;
            }
            
            if (this.$refs.form.validate()) {
                let sticker = {
                    hotel_id: this.object,
                    type_stickers_id: this.stickers.type,
                    number_stickers: this.stickers.qtt,
                    country: this.address.country, 
                    city: this.address.city, 
                    delivery_address: this.address.delivery_address, 
                    house: this.address.house, 
                    apartment: this.address.apartment, 
                    stickers_order_status_id: 5, // 2 - delivery, // 1 - completed // 3 - canceled // 4 - ожидае 
                    image: this.stickers.image,
                    name: this.name,
                    phone: this.phone,
                };

                this.$store.dispatch('stickers/create', sticker).then(() => {
                    this.$refs.PopupSuccess.open();
                })
            } else {
                this.$nextTick(() => {
                    const el = this.$el.querySelector(".v-messages.error--text:first-of-type");
                    this.$vuetify.goTo(el, {
                        offset: 100
                    });
                    return;
                });
            }
        }
    }
};
</script>

<style lang="scss">
.stickers__fields {
    @include col(9);
    display: flex;
    flex-wrap: wrap;
    max-width: 570px;
    margin: 0;
}

.stickers-logo {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    width: 100%;
}

.stickers__design {
    margin-bottom: 20px;
    padding: 0 15px;
    p {
        padding: 20px 20px 20px 60px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
        background: #ffd900 url(~@/assets/icons/info_black.svg) 20px center/20px no-repeat;
    }
}

.stickers-total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.stickers-total__text {
    font-weight: 500;
    font-size: 16px;
}

.stickers-total__price {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.21;
}

.stickers-type {
    position: relative;
    display: block;
    margin-bottom: 40px;
    cursor: pointer;
}

.stickers-type__img {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    &.active {
        &::before {
            content: '';
            position: absolute;
            top: -4px;
            left: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border-radius: 5px;
            border: solid 4px #3fa535;
        }
    }
}

.stickers-type__radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;

    &.active + .stickers-type__text {
        &::after {
            opacity: 1;
        }
    }
}

.stickers-type__text {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    font-weight: 500;
    font-size: 16px;
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 4px;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
    &::before {
        background-color: #e4e4e4;
    }
    &::after {
        background: #3fa535 url(~@/assets/icons/check.svg) center/11px no-repeat;
        opacity: 0;
    }
}


.stickers-file-text {
    margin: 0 !important;
    font-weight: 500;
    font-size: 14px;
    color: #7f8385;
}

</style>